import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import { SlateProvider, useSlateContext } from './context/SlateContext';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Home from './components/Home';
import PlayerStats from './components/PlayerStats';
import LineupOptimizer from './components/LineupOptimizer';
import ContestSimulator from './components/ContestSimulator';
import Documentation from './components/Documentation';
import SubscriptionPage from './components/SubscriptionPage';
import { AuthProvider, useAuth } from './context/AuthContext';
import { SimulationResults } from './types';
import CircularProgress from '@mui/material/CircularProgress';
import { SubscriptionProvider } from './context/SubscriptionContext';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrophy, faSackDollar, faRankingStar, faPercent, faChartLine, faUsers, faCoins } from '@fortawesome/free-solid-svg-icons';
import { Box, Typography } from '@mui/material';
import { LineupProvider } from './context/LineupContext';
import { PersistentStateProvider } from './context/PersistentStateContext';
import LoginPage from './components/LoginPage';
import SignupPage from './components/SignupPage';
library.add(faTrophy, faSackDollar, faRankingStar, faPercent, faChartLine, faUsers, faCoins);

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { isLoggedIn, isLoading } = useAuth();

    if (isLoading) {
        return <CircularProgress />;
    }

    return isLoggedIn ? <>{children}</> : <Navigate to="/" />;
};

function AppContent() {
    const [simulationResults, setSimulationResults] = useState<SimulationResults | null>(null);
    const [resetKey, setResetKey] = useState(0);
    const { sport } = useSlateContext();
    const { isLoading } = useAuth();


    const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
        const { isLoggedIn, isLoading } = useAuth();

        if (isLoading) {
            return <CircularProgress />;
        }

        // If the user isn't logged in, display a simple message
        if (!isLoggedIn) {
            return (
                <Box sx={{ textAlign: 'center', mt: 8 }}>
                    <Typography variant="h5" gutterBottom>
                        Please log in or sign up to access this feature.
                    </Typography>
                </Box>
            );
        }

        // If logged in, show the protected content
        return <>{children}</>;
    };


    useEffect(() => {
        const storedResults = sessionStorage.getItem('simulationResults');
        if (storedResults) {
            setSimulationResults(JSON.parse(storedResults));
        }
    }, []);

    useEffect(() => {
        // Reset when sport changes
        setResetKey(prevKey => prevKey + 1);
        setSimulationResults(null);
        sessionStorage.removeItem('simulationResults');
    }, [sport]);

    const updateSimulationResults = (results: SimulationResults) => {
        setSimulationResults(results);
        sessionStorage.setItem('simulationResults', JSON.stringify(results));
    };

    if (isLoading) {
        return <CircularProgress />;
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <Header />
            <Sidebar />
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/player-stats-and-stacks" element={<PlayerStats />} />
                    <Route path="/lineup-optimizer" element={<LineupOptimizer />} />
                    <Route
                        path="/contest-simulator"
                        element={
                            <ContestSimulator

                            />
                        }
                    />
                    <Route path="/documentation" element={<Documentation />} />
                    <Route path="/subscription" element={
                        <ProtectedRoute>
                            <SubscriptionPage />
                        </ProtectedRoute>
                    } />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/signup" element={<SignupPage />} />
                </Routes>
            </Box>
        </Box>
    );
}

function App() {
    return (
        <PersistentStateProvider>
            <AuthProvider>
                <SubscriptionProvider>

                    <SlateProvider>
                        <LineupProvider>
                            <ThemeProvider theme={theme}>
                                <CssBaseline />
                                <Router>
                                    <AppContent />
                                </Router>
                            </ThemeProvider>
                        </LineupProvider>
                    </SlateProvider>

                </SubscriptionProvider>
            </AuthProvider>
        </PersistentStateProvider>
    );
}

export default App;