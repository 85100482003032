import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useAuth } from '../context/AuthContext';
import AuthModal from './Auth/AuthModal';
import SlateFilters from './SlateFilters';
import { useSlateContext } from '../context/SlateContext';
import { FaDiscord, FaInstagram } from 'react-icons/fa';

function Header() {
    const { isLoggedIn, logout, user } = useAuth();
    const [authModalOpen, setAuthModalOpen] = useState(false);
    const { updateFilters } = useSlateContext();

    const handleFilterChange = (site: string, sport: string, date: string | null, slateId: number | null, slateType: 'Classic' | 'Showdown' | null) => {
        // console.log('Filter changed in Header', { sport, date, slateId, slateType });
        updateFilters(site, sport, date, slateId, slateType);
    };

    return (
        <AppBar
            position="fixed"
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                bgcolor: '#262727', // Dark grey background
                color: '#ffffff', // White text color
                boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2)',
            }}
        >
            <Toolbar sx={{ minHeight: 80, px: 2 }}>
                <img
                    src="/logo.png"
                    alt="Uncertain Edge Logo"
                    style={{ height: '60px', marginRight: '10px', marginBottom: '10px' }}
                />
                <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', mx: 2 }}>
                    <SlateFilters onFilterChange={handleFilterChange} />
                </Box>
                <Box sx={{ flexShrink: 0 }}>
                    {/* Add Discord icon here */}
                    <Button
                        onClick={() => window.open('https://discord.com/invite/nQyuKmbsrb', '_blank')}
                        sx={{
                            minWidth: 'auto',
                            p: 1,
                            mr: 1,
                            color: '#ffffff',
                            '&:hover': {
                                color: '#7289da'  // Discord brand color
                            }
                        }}
                    >
                        <FaDiscord size={24} />
                    </Button>

                    <Button
                        onClick={() => window.open('https://instagram.com/uncertainedge', '_blank')}
                        sx={{
                            minWidth: 'auto',
                            p: 1,
                            mr: 2,
                            color: '#ffffff',
                            '&:hover': {
                                color: '#E1306C'  // Instagram brand color
                            }
                        }}
                    >
                        <FaInstagram size={24} />
                    </Button>

                    {isLoggedIn ? (
                        <>
                            <Typography variant="body2" sx={{ mr: 1, display: 'inline', fontSize: '0.9rem', color: '#ffffff' }}>
                                {user?.email}
                            </Typography>
                            <Button color="inherit" onClick={logout} size="small" sx={{ fontSize: '0.9rem', color: '#ffffff' }}>Logout</Button>
                        </>
                    ) : (
                        <Button color="inherit" onClick={() => setAuthModalOpen(true)} size="small" sx={{ fontSize: '0.9rem', color: '#ffffff' }}>Login / Sign Up</Button>
                    )}
                </Box>
            </Toolbar>
            <AuthModal open={authModalOpen} onClose={() => setAuthModalOpen(false)} />
        </AppBar>
    );
}

export default Header;