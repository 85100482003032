import React, { createContext, useState, useContext, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { StackingInfo, NFLTeamStack, StackRule, Player, Game, CustomProjection, TeamStack, PlayerGroup, PlayerPoolGroup } from '../types';


interface EditedPlayer {
    Proj?: number;
    StdDev?: number;
    Own?: number;
    Olimit?: number;
}

// Add this near your other interfaces
interface LineupState {
    lineups: any[]; // Replace 'any' with your lineup type
    simulatedLineups: any[];
    lineupResults: any[];
    uploadedLineups: UploadedLineup[];
}

export interface UploadedLineup {
    lineupId: string;
    players: {
        playerId: string;
        position: string;
        salary: number;
        projection: number;
    }[];
    totalProjection: number;
    totalSalary: number;
}

interface SimulationResults {
    // Define the structure of your simulation results here
    // For example:
    lineups: any[];
    stackFrequencySummary: any[];
    // ... other properties ...
}

interface Settings {
    salaryRange: [number, number];
    totalOwnership: [number, number];
    hittersVsPitcher: number;
    rbDstStack: boolean;
    maxPlayersPerTeam: number;
    flexPositions: {
        WR: boolean;
        RB: boolean;
        TE: boolean;
    };
    forceLowOwnedPlayers: boolean;
    numberOfLowOwnedPlayers: number;
    lowOwnThreshold: number;
    useRandomness: boolean;
    usePlayerDistributions: boolean;
    useCorrelatedDistributions: boolean;
    randomnessPercentage: number;
    positionLimits: {
        [key: string]: number;
    };
    showdownCombinations: { [key: string]: boolean };
    minQBStackSize: number;
    maxQBStackSize: number;
    globalBringBackSize: number;
}


export interface PersistentState {
    numberOfLineups: number;
    minProj: number | '';
    minOwn: number | '';
    maxOwn: number | '';
    minValue: number | '';
    selectedPlayerIds: string[];
    showdownCombinations: { [key: string]: boolean };
    stackingInfo: StackingInfo;
    settings: Settings;
    editedPlayers: { [playerId: string]: EditedPlayer };
    selectedPositions: string[];
    selectedTeams: string[];
    searchTerm: string;
    slateId: number | null;
    sport: string;
    site: string;
    date: string | null;
    slateType: 'Classic' | 'Showdown' | null;
    players: Player[];
    lineups: any[]; // Replace 'any' with a more specific type if available
    isOptimizerStarting: boolean;
    lineupProgress: number,
    warnings: string[];
    playerExposures: { [key: string]: any }; // Replace 'any' with a more specific type if available
    usingCustomProjections: boolean;
    games: Game[];
    showResults: boolean;
    totalLineups: number;
    nflTeamStacks: NFLTeamStack[];
    positions: string[];
    customProjections: CustomProjection[];
    isLoading: boolean;
    isLineupGenerationComplete: boolean;
    lineupError: string | null;
    selectedRegularRows: string[];
    selectedCPTRows: string[];
    value: number;
    lineupResults: any[]; // Add this line
    simulatedLineups: any[]; // Add this line
    stackFrequencySummary: any[]; // Add this line
    contestSize: string;
    simOption: string;
    selectedRows: string[];
    uploadedLineups: UploadedLineup[];
    simulationResults: SimulationResults | null;
    error: string | null;
    playerGroups: PlayerGroup[];
    playerPoolGroups: PlayerPoolGroup[];
}

interface PersistentStateContextType {
    persistentState: PersistentState;
    updatePersistentState: (updates: Partial<PersistentState> | ((prevState: PersistentState) => Partial<PersistentState>)) => void;
    resetStateForNewSlate: () => void;
}
const defaultState: PersistentState = {
    numberOfLineups: 10,
    minProj: '',
    minOwn: '',
    maxOwn: '',
    minValue: '',
    selectedPlayerIds: [],
    showdownCombinations: {
        'TEAM1_5-TEAM2_1': true,
        'TEAM1_4-TEAM2_2': true,
        'TEAM1_3-TEAM2_3': true,
        'TEAM1_2-TEAM2_4': true,
        'TEAM1_1-TEAM2_5': true,
        'TEAM2_5-TEAM1_1': true,
        'TEAM2_4-TEAM1_2': true,
        'TEAM2_3-TEAM1_3': true,
        'TEAM2_2-TEAM1_4': true,
        'TEAM2_1-TEAM1_5': true,
    },
    stackingInfo: {
        primaryStacks: [],
        secondaryStacks: [],
        usePrimaryStacks: false,
        useSecondaryStacks: false,
        primaryStackCount: null,
        secondaryStackCount: null,
        useBringBack: false,
        rbDstStack: false,
        bringBackSize: 0,
        nflTeamStacks: [],
        showdownStackRules: [],
        teamStacks: [],
    },
    editedPlayers: {},
    selectedPositions: [],
    selectedTeams: [],
    searchTerm: '',
    slateId: null,
    sport: '',
    site: '',
    date: null,
    slateType: null,
    players: [],
    lineups: [],
    isOptimizerStarting: false,
    lineupProgress: 0,
    warnings: [],
    playerExposures: {},
    usingCustomProjections: false,
    totalLineups: 0,
    games: [],
    showResults: false,
    nflTeamStacks: [],
    positions: [],
    customProjections: [],
    isLoading: false,
    isLineupGenerationComplete: false,
    lineupError: null,
    selectedRegularRows: [],
    selectedCPTRows: [],
    value: 0,
    settings: {
        salaryRange: [25000, 50000],
        totalOwnership: [0, 300],
        hittersVsPitcher: 0,
        rbDstStack: false,
        maxPlayersPerTeam: 4,
        flexPositions: {
            WR: true,
            RB: true,
            TE: true,
        },
        forceLowOwnedPlayers: false,
        numberOfLowOwnedPlayers: 0,
        lowOwnThreshold: 10,
        useRandomness: true,
        usePlayerDistributions: false,
        useCorrelatedDistributions: false,
        randomnessPercentage: 20,
        positionLimits: {
            ALL: 100,
            P: 100,
            C: 100,
            '1B': 100,
            '2B': 100,
            '3B': 100,
            SS: 100,
            OF: 100,
            QB: 100,
            RB: 100,
            WR: 100,
            TE: 100,
            DST: 100,
        },
        minQBStackSize: 0,
        maxQBStackSize: 3,
        globalBringBackSize: 0,
        showdownCombinations: {},
    },
    lineupResults: [],
    simulatedLineups: [],
    stackFrequencySummary: [],
    contestSize: '',
    simOption: '',
    selectedRows: [],
    uploadedLineups: [],
    simulationResults: null,
    error: null,
    playerGroups: [],
    playerPoolGroups: [],
};

export const PersistentStateContext = createContext<PersistentStateContextType | undefined>(undefined);

export const PersistentStateProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    // Just use React state, no localStorage
    const [persistentState, setPersistentState] = useState<PersistentState>(defaultState);

    const updatePersistentState = useCallback((updates: Partial<PersistentState> | ((prevState: PersistentState) => Partial<PersistentState>)) => {
        setPersistentState(prevState => {
            return typeof updates === 'function' ? { ...prevState, ...updates(prevState) } : { ...prevState, ...updates };
        });
    }, []);

    const resetStateForNewSlate = useCallback(() => {
        setPersistentState(prevState => ({
            ...defaultState,
            site: prevState.site,
            sport: prevState.sport,
            date: prevState.date,
            slateId: prevState.slateId,
            slateType: prevState.slateType,
        }));
    }, []);

    const contextValue = useMemo(() => ({
        persistentState,
        updatePersistentState,
        resetStateForNewSlate
    }), [persistentState, updatePersistentState, resetStateForNewSlate]);

    return (
        <PersistentStateContext.Provider value={contextValue}>
            {children}
        </PersistentStateContext.Provider>
    );
};

export const usePersistentState = () => {
    const context = useContext(PersistentStateContext);
    if (context === undefined) {
        throw new Error('usePersistentState must be used within a PersistentStateProvider');
    }
    return context;
};









