import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { BaseLineup } from '../../types';

interface LineupTableProps {
    lineups: BaseLineup[];
    sport: 'MLB' | 'NFL' | 'NBA';
    isShowdown: boolean;
    site: 'DraftKings' | 'FanDuel';
}

const LineupTable: React.FC<LineupTableProps> = React.memo(({ lineups, sport, isShowdown, site }) => {
    // console.log('5. LineupTable rendering', lineups.length);
    const getPosition = (player: BaseLineup['players'][0]) => player.position.toLowerCase();

    const processNBALineup = (lineup: BaseLineup): (BaseLineup['players'][0] | null)[] => {
        if (site === 'DraftKings') {
            // DK positions: PG, SG, SF, PF, C, G, F, UTIL
            const processed = Array(8).fill(null);
            const positionOrder = ['PG', 'SG', 'SF', 'PF', 'C', 'G', 'F', 'UTIL'];
            const positionCounts: { [key: string]: number } = {};
            let gAssigned = false;
            let fAssigned = false;
            let utilAssigned = false;

            // First pass: Assign primary positions
            lineup.players.forEach(player => {
                const positions = player.position.split('/');
                if (!positionCounts[positions[0]]) {
                    positionCounts[positions[0]] = 0;
                }
                positionCounts[positions[0]]++;
            });

            // Second pass: Place players in their slots
            lineup.players.forEach(player => {
                const positions = player.position.split('/');
                let placed = false;

                // Try primary position first
                const primaryPos = positions[0];
                const primaryIndex = positionOrder.indexOf(primaryPos);
                if (primaryIndex !== -1 && !processed[primaryIndex]) {
                    processed[primaryIndex] = player;
                    placed = true;
                }

                // If not placed and eligible for G slot
                if (!placed && !gAssigned && (positions.includes('PG') || positions.includes('SG'))) {
                    const gIndex = positionOrder.indexOf('G');
                    if (!processed[gIndex]) {
                        processed[gIndex] = player;
                        gAssigned = true;
                        placed = true;
                    }
                }

                // If not placed and eligible for F slot
                if (!placed && !fAssigned && (positions.includes('SF') || positions.includes('PF'))) {
                    const fIndex = positionOrder.indexOf('F');
                    if (!processed[fIndex]) {
                        processed[fIndex] = player;
                        fAssigned = true;
                        placed = true;
                    }
                }

                // If still not placed, try UTIL
                if (!placed && !utilAssigned) {
                    const utilIndex = positionOrder.indexOf('UTIL');
                    if (!processed[utilIndex]) {
                        processed[utilIndex] = player;
                        utilAssigned = true;
                    }
                }
            });

            return processed;
        } else {
            // FanDuel positions: PG, PG, SG, SG, SF, SF, PF, PF, C
            const processed = Array(9).fill(null);
            const positionCounts: { [key: string]: number } = {
                PG: 0, SG: 0, SF: 0, PF: 0, C: 0
            };

            lineup.players.forEach(player => {
                const positions = player.position.split('/');
                const primaryPos = positions[0];

                if (positionCounts[primaryPos] < 2) {
                    // Find the next empty slot for this position
                    let slotIndex = -1;
                    if (primaryPos === 'PG') slotIndex = positionCounts[primaryPos];
                    else if (primaryPos === 'SG') slotIndex = 2 + positionCounts[primaryPos];
                    else if (primaryPos === 'SF') slotIndex = 4 + positionCounts[primaryPos];
                    else if (primaryPos === 'PF') slotIndex = 6 + positionCounts[primaryPos];
                    else if (primaryPos === 'C') slotIndex = 8;

                    if (slotIndex !== -1 && !processed[slotIndex]) {
                        processed[slotIndex] = player;
                        positionCounts[primaryPos]++;
                    }
                }
            });

            return processed;
        }
    };


    const processMLBLineup = (lineup: BaseLineup): (BaseLineup['players'][0] | null)[] => {
        const processedPlayers: (BaseLineup['players'][0] | null)[] = new Array(10).fill(null);
        const positionCounts: { [key: string]: number } = {};
        const positionOrder = ['P', 'P', 'C', '1B', '2B', '3B', 'SS', 'OF', 'OF', 'OF'];

        lineup.players.forEach(player => {
            const positionIndex = positionOrder.indexOf(player.position);
            if (positionIndex !== -1) {
                const count = positionCounts[player.position] || 0;
                let availableIndex = -1;

                if (player.position === 'OF') {
                    availableIndex = positionOrder.findIndex((pos, index) => pos === 'OF' && !processedPlayers[index]);
                } else {
                    availableIndex = positionOrder.indexOf(player.position, positionIndex + count);
                }

                if (availableIndex !== -1 && !processedPlayers[availableIndex]) {
                    processedPlayers[availableIndex] = player;
                    positionCounts[player.position] = (positionCounts[player.position] || 0) + 1;
                }
            }
        });

        return processedPlayers;
    };

    const processNFLLineup = (lineup: BaseLineup): (BaseLineup['players'][0] | null)[] => {
        // console.log('Processing NFL lineup:', lineup);
        const processed: { [key: string]: BaseLineup['players'][0][] } = {
            QB: [], RB: [], WR: [], TE: [], DST: [], FLEX: []
        };

        lineup.players.forEach(player => {
            // console.log('Processing player:', player);
            const position = getPosition(player);
            if (!position) {
                console.error('Player missing position:', player);
                return;
            }
            if (['rb', 'wr', 'te'].includes(position)) {
                if (processed[position.toUpperCase()].length < (position === 'te' ? 1 : (position === 'rb' ? 2 : 3))) {
                    processed[position.toUpperCase()].push(player);
                } else {
                    processed.FLEX.push(player);
                }
            } else if (['qb', 'dst'].includes(position)) {
                processed[position.toUpperCase()].push(player);
            } else {
                console.warn('Unknown position:', position);
            }
        });

        // console.log('Processed lineup:', processed);

        return [
            processed.QB[0] || null,
            processed.RB[0] || null,
            processed.RB[1] || null,
            processed.WR[0] || null,
            processed.WR[1] || null,
            processed.WR[2] || null,
            processed.TE[0] || null,
            processed.FLEX[0] || null,
            processed.DST[0] || null
        ];
    };

    const processShowdownLineup = (lineup: BaseLineup): (BaseLineup['players'][0] | null)[] => {
        const isFanDuel = site === 'FanDuel';
        const processed: (BaseLineup['players'][0] | null)[] = new Array(isFanDuel ? 5 : 6).fill(null);
        const captain = lineup.players.find(p => getPosition(p) === (isFanDuel ? 'cpt' : 'cpt'));
        if (captain) processed[0] = captain;
        const flexPlayers = lineup.players.filter(p => getPosition(p) === 'flex');
        flexPlayers.forEach((player, index) => {
            if (index < (isFanDuel ? 4 : 5)) processed[index + 1] = player;
        });
        return processed;
    };

    const columns: GridColDef[] = useMemo(() => {
        const baseColumns: GridColDef[] = [
            { field: 'lineupId', headerName: 'ID', width: 50 },
            {
                field: 'totalProjection',
                headerName: 'Projection',
                width: 70,
                renderCell: (params: GridRenderCellParams) => {
                    const value = params.value;
                    if (value === null || value === undefined) return '';
                    const numValue = typeof value === 'number' ? value : parseFloat(String(value));
                    return isNaN(numValue) ? '' : numValue.toFixed(1);
                }
            },
            {
                field: 'totalOwnership',
                headerName: 'Own%',
                width: 70,
                renderCell: (params: GridRenderCellParams) => {
                    const value = params.value;
                    if (value === null || value === undefined) return '';
                    const numValue = typeof value === 'number' ? value : parseFloat(String(value));
                    return isNaN(numValue) ? '' : `${numValue.toFixed(2)}%`;
                }
            },
            { field: 'totalSalary', headerName: 'Salary', width: 70 },
        ];

        let positionColumns: GridColDef[];
        if (isShowdown) {
            const positions = site === 'FanDuel'
                ? ['CPT', 'FLEX', 'FLEX', 'FLEX', 'FLEX']
                : ['CPT', 'FLEX', 'FLEX', 'FLEX', 'FLEX', 'FLEX'];
            positionColumns = positions.map((pos, index) => ({
                field: `player_${index}`,
                headerName: pos,
                width: 100,
                renderCell: (params: GridRenderCellParams) => {
                    const player = params.value as BaseLineup['players'][0] | null;
                    return player ? `${player.playerId}` : '';
                },
            }));
        }
        else if (sport === 'NBA') {
            const positions = site === 'DraftKings'
                ? ['PG', 'SG', 'SF', 'PF', 'C', 'G', 'F', 'UTIL']
                : ['PG', 'PG', 'SG', 'SG', 'SF', 'SF', 'PF', 'PF', 'C'];
            positionColumns = positions.map((pos, index) => ({
                field: `player_${index}`,
                headerName: pos,
                width: 100,
                renderCell: (params: GridRenderCellParams) => {
                    const player = params.value as BaseLineup['players'][0] | null;
                    return player ? `${player.playerId}` : '';
                },
            }));
        } else if (sport === 'NFL') {
            positionColumns = ['QB', 'RB', 'RB', 'WR', 'WR', 'WR', 'TE', 'FLEX', 'DST'].map((pos, index) => ({
                field: `player_${index}`,
                headerName: pos,
                width: 100,
                renderCell: (params: GridRenderCellParams) => {
                    const player = params.value as BaseLineup['players'][0] | null;
                    return player ? `${player.playerId}` : '';
                },
            }));
        } else {
            positionColumns = ['P', 'P', 'C', '1B', '2B', '3B', 'SS', 'OF', 'OF', 'OF'].map((pos, index) => ({
                field: `player_${index}`,
                headerName: pos,
                width: 100,
                renderCell: (params: GridRenderCellParams) => {
                    const player = params.value as BaseLineup['players'][0] | null;
                    return player ? `${player.playerId}` : '';
                },
            }));
        }

        return [...baseColumns, ...positionColumns];
    }, [sport, isShowdown]);

    const rows = useMemo(() => {
        // console.log('Creating rows from lineups:', lineups);
        if (!lineups || lineups.length === 0) return [];
        return lineups.map((lineup, index) => {
            let processedPlayers: (BaseLineup['players'][0] | null)[];
            if (isShowdown) {
                processedPlayers = processShowdownLineup(lineup);
            } else if (sport === 'NBA') {
                processedPlayers = processNBALineup(lineup);
            } else if (sport === 'NFL') {
                processedPlayers = processNFLLineup(lineup);
            } else {
                processedPlayers = processMLBLineup(lineup);
            }

            // console.log('Processed players:', processedPlayers);

            // Ensure there's always a unique id for each row
            const rowId = lineup.lineup_id || lineup.lineupId || `generated-id-${index}`;

            return {
                id: rowId,
                lineupId: rowId,
                totalProjection: lineup.totalProjection,
                totalSalary: lineup.totalSalary,
                totalOwnership: lineup.totalOwnership,
                ...processedPlayers.reduce((acc, player, index) => {
                    acc[`player_${index}`] = player;
                    return acc;
                }, {} as Record<string, BaseLineup['players'][0] | null>)
            };
        });
    }, [lineups, sport, isShowdown]);

    return (
        <Box sx={{ height: 500, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 50, page: 0 },
                    },
                }}
                pageSizeOptions={[50, 75, 100]}
                disableRowSelectionOnClick
            />
        </Box>
    );
});

export default LineupTable;