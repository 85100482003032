import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText, Paper, Container, Button, CircularProgress, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { useSubscription } from '../context/SubscriptionContext';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth, updateEmail, sendPasswordResetEmail, reauthenticateWithCredential, EmailAuthProvider, deleteUser } from "firebase/auth";
import { doc, deleteDoc } from "firebase/firestore";
import { db } from '../firebase'; // Ensure this import is correct for your project
import { useNavigate } from 'react-router-dom';
import { FaDiscord, FaInstagram, FaEnvelope } from 'react-icons/fa';

const SubscriptionPage: React.FC = () => {
    const { user } = useAuth();
    const { userSubscription, isLoading } = useSubscription();
    const stripeRef = useRef<HTMLDivElement>(null);
    const [portalLoading, setPortalLoading] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deletePassword, setDeletePassword] = useState('');
    const [deleteError, setDeleteError] = useState('');
    const auth = getAuth();
    const navigate = useNavigate();


    useEffect(() => {
        if (stripeRef.current && !stripeRef.current.hasChildNodes() && userSubscription?.subscriptionTier === 'free') {
            const script = document.createElement('script');
            script.src = 'https://js.stripe.com/v3/pricing-table.js';
            script.async = true;
            script.onload = () => {
                const stripeElement = document.createElement('stripe-pricing-table');
                stripeElement.setAttribute('pricing-table-id', 'prctbl_1Q1esOJ4Q988vnWCrecqaWx9');
                stripeElement.setAttribute('publishable-key', 'pk_live_51PxyVqJ4Q988vnWCl0Y2GvEk77TaK8dwL3x41cYK25Ot1hFO59AO8FCeym6hvpMVLGjANgkTFlzE8Zmm9c2D0PJq00ftl9t70h');
                if (user?.email) {
                    stripeElement.setAttribute('customer-email', user.email);
                }
                stripeRef.current?.appendChild(stripeElement);
            };
            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
                if (stripeRef.current) {
                    stripeRef.current.innerHTML = '';
                }
            };
        }
    }, [user, userSubscription]);

    const handleManageSubscription = async () => {
        if (!user) {
            console.error('User not authenticated');
            return;
        }

        setPortalLoading(true);
        try {
            const functions = getFunctions();
            const createPortalSession = httpsCallable<unknown, { url: string }>(functions, 'createPortalSession');

            const result = await createPortalSession();

            if (result.data && result.data.url) {
                window.location.assign(result.data.url);
            } else {
                console.error('No URL returned from createPortalSession');
            }
        } catch (error) {
            console.error('Error creating portal session:', error);
            alert('Failed to open subscription management portal. Please try again later.');
        } finally {
            setPortalLoading(false);
        }
    };

    const handleEmailChange = async () => {
        if (!user || !user.email) {
            alert('User not logged in or email not available');
            return;
        }
        try {
            const credential = EmailAuthProvider.credential(user.email, currentPassword);
            await reauthenticateWithCredential(user, credential);
            await updateEmail(user, newEmail);
            alert('Email updated successfully');
        } catch (error) {
            console.error('Error updating email:', error);
            alert('Failed to update email. Please check your current password and try again.');
        }
    };

    const handlePasswordReset = async () => {
        if (!user || !user.email) {
            alert('No user email found. Please log out and log back in.');
            return;
        }
        try {
            await sendPasswordResetEmail(auth, user.email);
            alert('Password reset email sent. Please check your inbox.');
        } catch (error) {
            console.error('Error sending reset email:', error);
            alert('Failed to send reset email. Please try again.');
        }
    };

    const handleDeleteAccount = async () => {
        const currentUser = auth.currentUser;

        if (!currentUser) {
            setDeleteError('User not logged in');
            return;
        }

        if (!deletePassword) {
            setDeleteError('Password is required to delete your account.');
            return;
        }

        try {
            const credential = EmailAuthProvider.credential(currentUser.email!, deletePassword);
            await reauthenticateWithCredential(currentUser, credential);

            const functions = getFunctions();
            const cancelSubscription = httpsCallable(functions, 'cancelSubscription');
            try {
                await cancelSubscription();
                console.log('Subscription cancelled successfully');
            } catch (subscriptionError) {
                console.warn('Failed to cancel subscription, but proceeding with account deletion', subscriptionError);
            }

            await deleteDoc(doc(db, "users", currentUser.uid));
            await deleteUser(currentUser);

            setOpenDeleteDialog(false);
            alert('Your account has been deleted successfully.');
            navigate('/');
        } catch (error) {
            console.error('Error deleting account:', error);
            if (error instanceof Error && error.message.includes("auth/wrong-password")) {
                setDeleteError('Incorrect password. Account deletion failed.');
            } else {
                setDeleteError('Failed to delete account. Please try again later or contact support.');
            }
        }
    };

    const freeFeatures = [
        'Access to view fantasy point and ownership projections',
        'Build a lineup using our advanced optimizer. Use this as a way to see the functionality offered',
    ];

    if (isLoading) {
        return <CircularProgress />;
    }

    return (
        <Container maxWidth="md">
            <Box sx={{ mt: 12, mb: 4 }}>
                <Typography variant="h4" gutterBottom>
                    Your Subscription
                </Typography>
                {userSubscription && (
                    <Paper elevation={3} sx={{ p: 4, mt: 4, borderRadius: 2 }}>
                        <Typography variant="h5" gutterBottom>
                            Current Plan: {userSubscription.subscriptionTier.charAt(0).toUpperCase() + userSubscription.subscriptionTier.slice(1)}
                        </Typography>
                        {userSubscription.subscriptionTier === 'free' ? (
                            <>
                                <Typography variant="body1" sx={{ mt: 2, mb: 3 }}>
                                    Enjoy these features with your free account:
                                </Typography>
                                <List>
                                    {freeFeatures.map((feature, index) => (
                                        <ListItem key={index}>
                                            <ListItemIcon>
                                                <CheckCircleOutlineIcon color="primary" />
                                            </ListItemIcon>
                                            <ListItemText primary={feature} />
                                        </ListItem>
                                    ))}
                                </List>
                                <Typography variant="body1" sx={{ mt: 3, mb: 4 }}>
                                    Upgrade your plan to unlock more features and take your DFS game to the next level!
                                </Typography>
                                <Box ref={stripeRef} sx={{ mt: 4, width: '100%' }}></Box>
                            </>
                        ) : (
                            <>
                                <Typography variant="body1" sx={{ mt: 2, mb: 3 }}>
                                    You're currently enjoying our {userSubscription.subscriptionTier} plan.
                                    Your subscription {userSubscription.subscriptionEndDate
                                        ? `renews on ${userSubscription.subscriptionEndDate.toLocaleDateString()}.`
                                        : 'is active.'}
                                </Typography>
                                <Button
                                    variant="contained"
                                    onClick={handleManageSubscription}
                                    disabled={portalLoading}
                                >
                                    {portalLoading ? <CircularProgress size={24} /> : 'Manage Subscription'}
                                </Button>
                            </>
                        )}
                    </Paper>
                )}

                <Paper elevation={3} sx={{ p: 4, mt: 4, borderRadius: 2 }}>
                    <Typography variant="h5" gutterBottom>Join Our Community</Typography>
                    <Typography variant="body1" sx={{ mb: 3 }}>
                        Connect with other DFS enthusiasts, get strategy, tips, and stay up to date on the latest features and content.
                    </Typography>

                    <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
                        <Button
                            variant="contained"
                            onClick={() => window.open('https://discord.com/invite/nQyuKmbsrb', '_blank')}
                            startIcon={<FaDiscord />}
                            sx={{
                                bgcolor: '#7289da',
                                '&:hover': {
                                    bgcolor: '#5b6eae'
                                }
                            }}
                        >
                            Join Discord Server
                        </Button>

                        <Button
                            variant="contained"
                            onClick={() => window.open('https://instagram.com/uncertainedge', '_blank')}
                            startIcon={<FaInstagram />}
                            sx={{
                                bgcolor: '#E1306C',
                                '&:hover': {
                                    bgcolor: '#b9255a'
                                }
                            }}
                        >
                            Follow on Instagram
                        </Button>

                        <Button
                            variant="contained"
                            onClick={() => window.open('https://uncertain-edge.kit.com/1f40881d47', '_blank')}
                            startIcon={<FaEnvelope />}
                            sx={{
                                bgcolor: '#2196f3',  // Material UI blue
                                '&:hover': {
                                    bgcolor: '#1976d2'
                                }
                            }}
                        >
                            Subscribe to Emails
                        </Button>
                    </Box>
                </Paper>

                {/* Account Information Section */}
                <Paper elevation={3} sx={{ p: 4, mt: 4, borderRadius: 2 }}>
                    <Typography variant="h5" gutterBottom>Account Information</Typography>
                    <Typography variant="body1">Email: {user?.email}</Typography>
                    <Button
                        variant="contained"
                        onClick={handlePasswordReset}
                        sx={{ mt: 2 }}
                    >
                        Reset Password
                    </Button>
                </Paper>

                {/* Account Deletion Section */}
                <Paper elevation={3} sx={{ p: 4, mt: 4, borderRadius: 2 }}>
                    <Typography variant="h5" gutterBottom>Account Deletion</Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                        Deleting your account will permanently remove all your data, including lineups and settings. This action cannot be undone.
                    </Typography>
                    <Button variant="contained" color="error" onClick={() => setOpenDeleteDialog(true)}>
                        Delete Account
                    </Button>
                </Paper>

                {/* Support Contact Section */}
                <Paper elevation={3} sx={{ p: 4, mt: 4, borderRadius: 2 }}>
                    <Typography variant="h5" gutterBottom>Support Contact</Typography>
                    <Typography variant="body2">
                        For any billing or account issues, please contact us at:
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', mt: 1 }}>
                        data@uncertainedge.com
                    </Typography>
                </Paper>
            </Box>

            {/* Account Deletion Confirmation Dialog */}
            <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
                <DialogTitle>Confirm Account Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete your account? This action cannot be undone and all your data will be permanently lost.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Enter your password to confirm"
                        type="password"
                        fullWidth
                        variant="outlined"
                        value={deletePassword}
                        onChange={(e) => setDeletePassword(e.target.value)}
                        error={!!deleteError}
                        helperText={deleteError}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setOpenDeleteDialog(false);
                        setDeletePassword('');
                        setDeleteError('');
                    }}>
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteAccount} color="error">
                        Delete Account
                    </Button>
                </DialogActions>
            </Dialog>

        </Container>

    );
};

export default SubscriptionPage;