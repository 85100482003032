// SignupPage.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Paper, Typography } from '@mui/material';
import SignupForm from '../components/Auth/SignupForm';

const SignupPage = () => {
    const navigate = useNavigate();

    const handleSuccess = () => {
        navigate('/'); // Redirect to main app after login
    };

    return (
        <Box
            sx={{
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#f5f5f5'
            }}
        >
            <Paper
                elevation={3}
                sx={{
                    p: 4,
                    width: '100%',
                    maxWidth: 600,
                    textAlign: 'center'
                }}
            >

                <Typography variant="h1" gutterBottom>
                    Sign Up to Find Your Edge
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Creating an account allows you to explore the DFS OS.
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Head over to the Profile page to start your FREE trial.
                </Typography>
                <SignupForm onSuccess={handleSuccess} />
            </Paper>
        </Box>
    );
};

export default SignupPage;