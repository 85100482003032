// LoginPage.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Paper, Typography } from '@mui/material';
import LoginForm from '../components/Auth/LoginForm';

const LoginPage = () => {
    const navigate = useNavigate();

    const handleSuccess = () => {
        navigate('/'); // Redirect to main app after login
    };

    return (
        <Box
            sx={{
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#f5f5f5'
            }}
        >
            <Paper
                elevation={3}
                sx={{
                    p: 4,
                    width: '100%',
                    maxWidth: 600,
                    textAlign: 'center'
                }}
            >
                <Typography variant="h5" gutterBottom>
                    Welcome Back!
                </Typography>
                <LoginForm onSuccess={handleSuccess} />
            </Paper>
        </Box>
    );
};

export default LoginPage;